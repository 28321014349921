import {inject, injectable} from 'inversify';
import {InquiryFormStep} from '../components/InquiryForm/inquiryFormStep';
import {history} from '../index'
import store from '../store'
import {IFormValidatorService} from './formValidatorService';
import {changeWizardHeadStepIndex} from '../store/reducers/formSlice';
import {RestQueryParams} from "common-web";

export interface IStepManagerService {
    goNext(currentStep: InquiryFormStep): void;
    goPrev(currentStep: InquiryFormStep): void;
    goTo(stepName: InquiryFormStep): any;
    getWizardSteps(): any[];
    manageWizardHeadNo(): void;
    getStepIndex(step: InquiryFormStep): number;
}

@injectable()
class StepManagerService implements IStepManagerService {

    @inject('FormValidatorService') private formValidator: IFormValidatorService;

    history: any;

    constructor() {
        this.history = history;
    }

    public goNext(currentStep: InquiryFormStep): void {
        const state = store.getState();
        let stepArray = Array.from(state.form.formSteps);
        const currentStepIndex = stepArray.findIndex(step => step === currentStep);

        if (currentStepIndex === stepArray.length - 1) {
            console.log('it is a last step.  You cannot go further.');
            return;
        }
        this.goTo(stepArray[currentStepIndex + 1]);
    }

    public goPrev(currentStep: InquiryFormStep): void {
        const state = store.getState();
        let stepArray = Array.from(state.form.formSteps);
        const currentStepIndex = stepArray.findIndex(step => step === currentStep);
        if (currentStepIndex === 0) {
            console.log('it is a first step. Cannot go back.');
            return;
        }
        this.goTo(stepArray[currentStepIndex - 1]);
    }

    public goTo(stepName: InquiryFormStep): any {
        const state = store.getState();
        const params: {[key: string]: any} = {};

        if (state.form.inquiryForm.cid && !state.form.inquiryId) {
            params['cid'] = state.form.inquiryForm.cid;
        }

        if (state.form.inquiryForm.calendarId && !state.form.inquiryId) {
            params['cal'] = state.form.inquiryForm.calendarId;
        }
        const path: string[] = [''];
        // add clinic host if hosted by Clinic Panel
        if (state.form.isClinicHost) {
            path.push('c');
        }

        // add base host
        path.push('inquiry');

        // logged in user can't access Account Step
        path.push(stepName);

        // add inquiryId if exists
        if (state.form.inquiryId) {
            path.push(state.form.inquiryId);
        }

        this.history.push({
            pathname: path.join('/'),
            search: new RestQueryParams(params).prepareQuery()
        });
        //this.history.push(path.join('/'), {params});
    }

    public getWizardSteps(): any[] {
        const state = store.getState();

        const stepsObj: any[] = [];
        state.form.formSteps.forEach((stepName: InquiryFormStep, i: number) => {
            if (stepName === InquiryFormStep.SUCCESS) {
                return;
            }

            const stepHeadIndex = state.form.wizardHeadStepIndex;
            const stepIndex = this.getStepIndex(stepName);

            // step enabled max to headStep and only if previous step is valid
            const enabled = (i <= stepHeadIndex) && (stepsObj.length ? stepsObj[stepsObj.length - 1].isEnabled : true);

            // step is enabled and valid
            const valid = this.formValidator.isStepValid(stepName);

            stepsObj.push({
                name: stepName,
                isActive: stepName === state.form.currentStep,
                isEnabled: enabled,
                isValid: valid,
                isHead: stepHeadIndex === stepIndex,
            });
        });

        return stepsObj;
    }

    public manageWizardHeadNo(): void {
        const form = store.getState().form;
        const isCurrentStepValid = this.formValidator.isStepValid(form.currentStep);
        const currentStepIndex = this.getStepIndex(form.currentStep);

        if (currentStepIndex > form.wizardHeadStepIndex || !isCurrentStepValid) {
            store.dispatch(changeWizardHeadStepIndex(currentStepIndex));
        }
    }

    public getStepIndex(stepName: InquiryFormStep): number {
        const state = store.getState();

        const stepArray = Array.from(state.form.formSteps);

        const index = stepArray.findIndex((step: InquiryFormStep) => step === stepName);
        if (index === -1) {
            throw new Error('[StepManagerService]: Invalid step index.')
        }

        return index;
    }
}

export default StepManagerService;
