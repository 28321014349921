import moment from "moment";
import {ISummaryTableItem} from "./SummaryList";
import {IInquiryForm} from "../../../store/reducers/formSlice";
import {formatServerDateToString} from "common-web";

export class QuoteTableConfigFactory {
    create(inquiryForm: IInquiryForm, apiData: any): ISummaryTableItem[] {

        const globalStateForm = inquiryForm,
            clinicAccount = apiData.clinic.account,
            clinicTitle = apiData.clinic.companyName,
            clinicLocation = `${clinicAccount.address.city ? clinicAccount.address.city : ''} (${clinicAccount.address.country})`,
            price = apiData.payments[0].value.amount,
            currency = apiData.payments[0].value.currency.code;
        const dataFromApi = {
            name: globalStateForm.name,
            lastName: globalStateForm.lastName,
            // treatmentCategory: apiData.treatmentType.name,
            consultationDate: formatServerDateToString(apiData.startsAt),
            duration: this.getDuration(apiData.endsAt, apiData.startsAt),
            comment: apiData.notes,
            totalPrice: price,
        };
        const consultationPrice = dataFromApi.totalPrice ? ((dataFromApi.totalPrice / 100).toFixed(2)) : '0.00';

        let quoteTableConfig: ISummaryTableItem[] = [
            {
                name: 'clinic',
                label: 'form.consultationForm.summary.clinic.label',
                data: `${clinicTitle}, ${clinicLocation}`
            },
            // {
            //     name: 'treatment',
            //     label: 'form.consultationForm.summary.treatment.label',
            //     data: `${dataFromApi.treatmentCategory}`
            // },
            {
                name: 'consultationDate',
                label: 'form.consultationForm.summary.consultationDate.label',
                data: `${dataFromApi.consultationDate}, ${this.getHours(apiData.startsAt)}`
            },
            {
                name: 'duration',
                label: 'form.consultationForm.summary.duration.label',
                data: `${dataFromApi.duration} minutes`,
            },
            {
                name: 'patientName',
                label: 'form.consultationForm.summary.patientName.label',
                data: `${dataFromApi.name} ${dataFromApi.lastName}`
            },
            {
                name: 'comment',
                label: 'form.consultationForm.summary.comment.label',
                data: dataFromApi.comment,
            },
            {
                name: 'totalPrice',
                label: 'form.consultationForm.summary.totalPrice.label',
                data: `${consultationPrice} ${currency}`
            }
        ];

        return quoteTableConfig;
    }

    private getDuration(endsAt: string, startsAt: string) {
        let difference = new Date(endsAt).getTime() - new Date(startsAt).getTime();
        difference = Math.ceil(difference/1000/60);

        return difference;
    }

    private getHours(startsAt: string) {
        return moment(startsAt).format("HH:mm");
    }
}
