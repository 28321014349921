import { combineReducers } from "redux";
import saga from "./sagaSlice";
import metadataSlice from "./metadataSlice";
import formSlice from "./formSlice";
import authSlice from "./authSlice";
import {connectRouter} from "connected-react-router";
import {createBrowserHistory} from "history";
import {accountSlice, alertSlice, calendarSlice, consultationDataSlice} from "common-web";

const history = createBrowserHistory();

const rootReducer = combineReducers({
    saga: saga,
    account: accountSlice,
    alert: alertSlice,
    auth: authSlice,
    form: formSlice,
    consultationData: consultationDataSlice,
    metadata: metadataSlice,
    calendar: calendarSlice,
    router: connectRouter(history)
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
