import * as React from "react";
import styles from "./styles.module.scss";
import clinicLogo from "../../assets/images/logo_title.png";
import {Translation} from 'common-web';
import {InquiryFormStep} from "../InquiryForm/inquiryFormStep";
import {connect} from "react-redux";
import {RootState} from "../../store/reducers";
import {currentStepSelector} from "../../store/selectors/formSelectors";
import {RouteComponentProps, withRouter} from 'react-router-dom';

interface IInfoPanelProps extends RouteComponentProps {
    currentStep: InquiryFormStep;
}

class InfoPanel extends React.Component<IInfoPanelProps> {
    constructor(props: any) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.mobileAdditionalInformation}>
                </div>
                <article className={`${styles.formAdditionalInformation} ${styles[this.props.currentStep]}`}>
                    <header className={styles.additionalInformationHeader}>
                        <h1 className="sr-only">
                            <Translation text={`additionalInformation.heading`}/>
                        </h1>
                        <div className={styles.logoWrapper}>
                            <img className={styles.logoItemClinic} src={clinicLogo} alt=""/>
                        </div>
                    </header>
                </article>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: RootState) => ({
        currentStep: currentStepSelector(state),
    }),
    {}
)(withRouter(InfoPanel));

