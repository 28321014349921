import { Observable } from "rxjs";
import { clinicHunterRegistrationAPI } from "./provider/clinicHunterRegistration";
import {RestQueryParams} from "common-web";

export function confirmResetPasswordAPI(
  plainPassword: string,
  resetPasswordToken: string
): Observable<any> {
  return clinicHunterRegistrationAPI.post(
      `reset-password`,
      {
          plainPassword: plainPassword,
      },
      new RestQueryParams(),
      {
          'X-Authentication-Token': resetPasswordToken
      }
  );
}
