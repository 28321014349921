import {FormControlType, ValidationRules, IFormConfig} from "common-web";

export const treatmentFormConfig: typeof IFormConfig = {
  controlType: 'group',
  class: '',
  dataAccessor: (data: any) => data, // data is not grouped
  outputDataMapper: (data: any, previousStateSnapshot: any) => {
    Object.assign(previousStateSnapshot, data);

    return previousStateSnapshot;
  },
  controls: [
    {
      controlType: 'group',
      key: 'date',
      class: 'form-row',
      controls: {
        consultationDate: {
          hostClass: 'col-100',
          controlType: 'control',
          defaultValue: null,
          placeholder: 'form.consultationForm.treatment.formControls.consultationDate.label',
          formControlType: FormControlType.CALENDAR,
          maxDate: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString(),
          label: 'form.consultationForm.treatment.formControls.consultationDate.label',
          validationRules: [
            { name: ValidationRules.IS_REQUIRED },
          ],
        }
      }
    },
  ]
};
