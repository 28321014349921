import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";
import {Observable} from "rxjs";
import { RestQueryParams } from "common-web";

export function assignConsultationToUserAPI(
    inquiryId: string,
    accessToken: string,
    secret: string,
): Observable<any> {
    return clinicHunterInquiryAPI.put(
        `online_consultation/${inquiryId}/assign`,
        {},
        new RestQueryParams(),
        {
            Authorization: `Bearer ${accessToken}`,
            'X-Online-Consultation-Secret': secret,
        },
    );
}
