import {injectable} from 'inversify';
import store from '../store';
import {InquiryFormStep} from '../components/InquiryForm/inquiryFormStep';

export interface IFormValidatorService {
    isStepValid: (insuranceFormStep: InquiryFormStep) => boolean;
}

@injectable()
class FormValidatorService implements IFormValidatorService {

    public isStepValid(insuranceFormStep: InquiryFormStep) {
        const globalState = store.getState(),
            inquiryForm = globalState.form.inquiryForm;

        switch (insuranceFormStep) {
            case InquiryFormStep.TREATMENT:
                return (inquiryForm.consultationDate !== null);

            case InquiryFormStep.PATIENT:
                return (
                    inquiryForm.name !== null &&
                    inquiryForm.lastName !== null
                );

            case InquiryFormStep.SUMMARY:
                return true;

            case InquiryFormStep.ACCOUNT:
                return true;

            case InquiryFormStep.PAYMENT:
                return (
                    inquiryForm.cardholderName !== null &&
                    inquiryForm.termsAndConditions !== null &&
                    globalState.auth.isAuthenticated
                );

            case InquiryFormStep.SUCCESS:
                return true;

            default:
                throw new Error(`Invalid form step: ${insuranceFormStep}`)

        }
    }
}

export default FormValidatorService;
