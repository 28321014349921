import {inject, injectable} from "inversify";
import store from "../store";
import {Observable, of, throwError} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators";
import {loginAPI} from "../api/loginAPI";
import {
    changeInquiry,
    changeFormSteps,
    changeShowFormLoader,
    changeWizardHeadStepIndex,
    resetFormAndInitialize
} from "store/reducers/formSlice";
import {getLoginCredentials, setAuthState, setSecret} from "store/reducers/authSlice";
import {IAlertManagerService} from "./alertManagerService";
import {registerPatientAPI} from "../api/registerPatientAPI";
import {stepsNotAuthenticated} from "../components/FormHost/formSteps";
import {InquiryFormStep} from "../components/InquiryForm/inquiryFormStep";
import {setAccountState, userAPI, UserRole, updateAccountAPI} from "common-web";
import {IStepManagerService} from "./stepManagerService";
import {assignConsultationToUserAPI} from "../api/assignConsultationToUserAPI";

const jwtDecode = require("jwt-decode");
const uuid = require("uuid/v4");

export interface IAuthenticationFlowService {
    submitLogin(login: string, password: string): Observable<any>;
    submitRegistration(login: string, password: string): Observable<any>;
    logout(): void;
}

@injectable()
class AuthenticationFlowService implements IAuthenticationFlowService {
    @inject('AlertManagerService') private alertManager: IAlertManagerService;
    @inject('StepManagerService') private stepManager: IStepManagerService;

    public submitRegistration(login: string, password: string): Observable<any> {
        store.dispatch(changeShowFormLoader(true));
        return registerPatientAPI(login, password)
            .pipe(
                tap(() => {
                    this.alertManager.registrationSuccess();
                }),
                catchError((error) => {
                    this.alertManager.handleFormApiError(error.response);
                    store.dispatch(changeShowFormLoader(false));
                    return throwError(error);
                }),
                switchMap(() => this.submitLogin(login, password))
            )
    };

    public submitLogin(login: string, password: string): Observable<any> {
        store.dispatch(changeShowFormLoader(true));
        const globalState = store.getState();

        return loginAPI(login, password).pipe(
            switchMap((resp: any) => {
                const authToken = resp.authToken,
                    decoded = jwtDecode(resp.authToken),
                    userRoles = decoded.roles,
                    secret = globalState.auth.secret,
                    inquiryId = globalState.form.inquiryId,
                    inquiryForm = globalState.form.inquiryForm;
                let o: Observable<any> = new Observable<any>();
                if (userRoles.includes(UserRole.PATIENT)) {
                    const commonObservable = userAPI(authToken).pipe(
                        tap((resp: any) => {
                            if (!resp.account.firstName && !resp.account.lastName &&
                                inquiryForm && inquiryForm.name && inquiryForm.lastName) {
                                const payload = {
                                    id: resp.account.id,
                                    firstName: inquiryForm.name,
                                    lastName: inquiryForm.lastName,
                                    birthDate: resp.account.birthDate,
                                    address: resp.account.address,
                                    invoiceAddress: resp.account.invoiceAddress,
                                    phone: resp.account.phone,
                                    residency: resp.account.residency
                                };

                                return updateAccountAPI(
                                    resp.account.id,
                                    authToken,
                                    payload
                                ).pipe(
                                    tap((resp: any) => {
                                        return store.dispatch(setAccountState(
                                            {
                                                id: resp.id,
                                                firstName: resp.firstName,
                                                lastName: resp.lastName,
                                                birthDate: resp.birthdate,
                                                addressLine1: resp.address.addressLine1,
                                                addressLine2: resp.address.addressLine2,
                                                addressLine3: resp.address.addressLine3,
                                                postcode: resp.address.postcode,
                                                city: resp.address.city,
                                                country: resp.address.country,
                                                residency: resp.residency,
                                            }
                                        ));
                                    }),
                                    catchError((error) => {
                                        console.log(error);
                                        this.alertManager.handleFormApiError(error.response);
                                        return throwError(error);
                                    }),
                                ).subscribe();
                            }

                            return store.dispatch(setAccountState(
                                resp.account ? {
                                    id: resp.account.id,
                                    firstName: resp.account.firstName,
                                    lastName: resp.account.lastName,
                                    birthDate: resp.account.birthdate,
                                    addressLine1: resp.account.address.addressLine1,
                                    addressLine2: resp.account.address.addressLine2,
                                    addressLine3: resp.account.address.addressLine3,
                                    postcode: resp.account.address.postcode,
                                    city: resp.account.address.city,
                                    country: resp.account.address.country,
                                    residency: resp.account.residency,
                                } : null));
                        }),
                        catchError((error) => {
                            this.alertManager.handleFormApiError(error.response);
                            return throwError(error);
                        }),
                        tap(() => store.dispatch(getLoginCredentials(login, password)))
                    );
                    if (inquiryId && authToken && secret) {
                        o = assignConsultationToUserAPI(inquiryId, authToken, secret).pipe(
                            tap((inquiry: any) => {
                                store.dispatch(setSecret(null));
                                store.dispatch(changeInquiry(inquiry))
                            }),
                            catchError((error) => {
                                this.alertManager.handleFormApiError(error.response);
                                return throwError(error);
                            }),
                            switchMap(() => commonObservable)
                        )
                    } else {
                        o = commonObservable;
                    }
                    this.alertManager.loginSuccess();
                    return o;
                } else {
                    store.dispatch(changeShowFormLoader(false));
                    this.alertManager.loginFailure();
                    return of();
                }
            }),
            catchError((error) => {
                this.alertManager.handleFormApiError(error.response);
                store.dispatch(changeShowFormLoader(false));
                return throwError(error);
            }),
            tap(() => store.dispatch(changeShowFormLoader(false)))
        );
    };

    public logout() {
        this.alertManager.logoutSuccess();
        store.dispatch(resetFormAndInitialize());
        store.dispatch(setAccountState(null));
        store.dispatch(changeFormSteps(Array.from(stepsNotAuthenticated)));
        store.dispatch(changeWizardHeadStepIndex(0));
        this.stepManager.goTo(InquiryFormStep.TREATMENT);

        return store.dispatch(setAuthState(
            null,
            null,
            null,
            uuid(),
            null,
            false
        ));
    };
}

export default AuthenticationFlowService;
