import {injectable} from 'inversify';
import store from '../store';
import {InquiryFormStep} from '../components/InquiryForm/inquiryFormStep';
import {FormControlType, IFormConfig} from "common-web";
import {ISummaryTableItem} from "../components/InquiryForm/FormStepSummary/SummaryList";

export interface IFormConfigTranslationService {
    setTranslationKeys: (formConfig: typeof IFormConfig, stepName: InquiryFormStep) => typeof IFormConfig;
    setSummaryListTranslationKeys: (quoteTableConfig: ISummaryTableItem[], stepName: InquiryFormStep) => ISummaryTableItem[];
}

@injectable()
class FormConfigTranslationService implements IFormConfigTranslationService {

    public setTranslationKeys(formConfig: typeof IFormConfig, stepName: InquiryFormStep) {
        const globalState = store.getState(),
            clinicHost = globalState.form.isClinicHost,
            translatedFormConfig = Object.assign({}, formConfig);
        translatedFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    const currentControl = control.controls[key];
                    if (currentControl.type === FormControlType.CHECKBOX) {
                        if(currentControl.isCheckboxLabelRaw) {
                            return currentControl;
                        }
                        return currentControl.checkboxLabel = `form.${clinicHost ? 'clinicTelemedicineForm' : 'consultationForm'}.${stepName}.formControls.${key}.checkboxLabel`
                    }
                    currentControl.label = `form.${clinicHost ? 'clinicTelemedicineForm' : 'consultationForm'}.${stepName}.formControls.${key}.label`;
                    currentControl.placeholder = `form.${clinicHost ? 'clinicTelemedicineForm' : 'consultationForm'}.${stepName}.formControls.${key}.placeholder`;
                    return currentControl;
                });
            }
            return control;
        });
        return translatedFormConfig;
    }

    public setSummaryListTranslationKeys(quoteTableConfig: ISummaryTableItem[], stepName: InquiryFormStep) {
        const globalState = store.getState(),
            clinicHost = globalState.form.isClinicHost;
        const quoteTableConfigTranslated = quoteTableConfig.map((control: any) => {
            const currentControlLabelTranslated = `form.${clinicHost ? 'clinicTelemedicineForm' : 'consultationForm'}.${stepName}.${control.name}.label`;
            control.label = currentControlLabelTranslated;
            return control;
        });
        return quoteTableConfigTranslated;
    }
}

export default FormConfigTranslationService;
