import { Observable } from "rxjs";
import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";
import {RestQueryParams} from "common-web";

export function createEventPlaceholderAPI(
  credentials: {
    authToken: string | undefined,
    secret: string | undefined,
  },
  validFrom: string,
  validUntil: string,
  startsAt: string,
  endsAt: string | null,
  subjectName: string = 'online_consultation',
  subjectId: string,
  calendarId: string
): Observable<any> {
  const payload: any = {
    validFrom: validFrom,
    validUntil: validUntil,
    startsAt: startsAt,
    endsAt: endsAt,
    subjectName: subjectName = 'online_consultation',
    subjectId: subjectId,
    calendarId: calendarId
  };

  let headers: any = {
    accept: 'application/ld+json',
  };
  if (undefined !== credentials.authToken) {
    headers = {
      Authorization: `Bearer ${credentials.authToken}`,
    };
  } else {
    headers['X-Online-Consultation-Secret'] = credentials.secret;
  }

  return clinicHunterInquiryAPI.post(
    `calendar_event/create_placeholder`,
    payload,
    new RestQueryParams(),
    headers
  );
}
