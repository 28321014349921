import {Observable} from "rxjs";
import {RestQueryParams} from "common-web";
import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";

export function getStripeAccountIdAPI(
    paymentId: string,
    clinicAccountId: string,
    authToken: string
): Observable<any> {
    return clinicHunterInquiryAPI.post(
        `payments/${paymentId}/get_target_vendor_account_id`,
        {
            targetAccountId: clinicAccountId,
            paymentServiceName: "stripe"
        },
        new RestQueryParams(),
        {
            Authorization: `Bearer ${authToken}`,
        },
    );
}
