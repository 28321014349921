import * as React from "react";
import {Translation} from "common-web";
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {inquiryIdSelector, isClinicHostSelector,} from '../../../store/selectors/formSelectors';
import {changeCurrentStep,} from "../../../store/reducers/formSlice";
import {Redirect} from "react-router-dom";
import styles from "./styles.module.scss";
import {isAuthenticatedSelector, paymentTokenSelector} from "../../../store/selectors/authSelectors";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs"; // toDo wyjebać
import {InquiryFormStep} from '../inquiryFormStep';
import RegisterComponent from "../../AuthComponents/RegisterComponent";
import LoginComponent from "../../AuthComponents/LoginComponent";
import {Trans} from "react-i18next";

interface IFormStepAccountProps {
    readonly isAuthenticated: boolean;
    readonly inquiryId: string | null;
    readonly isClinicHost: boolean;
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly paymentToken: string | null;
}

interface IFormStepAccountState {
    accountCurrentTab: number;
}

class FormStepAccount extends React.Component<IFormStepAccountProps, IFormStepAccountState> {
    constructor(props: any) {
        super(props);
        this.state = {
            accountCurrentTab: 0,
        };
    }

    componentDidMount() {
        this.props.changeCurrentStep(InquiryFormStep.ACCOUNT);
    }

    render() {
        if (this.props.isAuthenticated) {
            let path = `${this.props.isClinicHost ? '/c' : ''}/inquiry/${InquiryFormStep.SUMMARY}`;
            if (this.props.inquiryId) {
                path += `/${this.props.inquiryId}`;
            }

            return (<Redirect to={path}/>);
        }

        const isFirstTabChosen = this.state.accountCurrentTab === 0;
        return (
            <React.Fragment>
                <Tabs className={styles.tabs}>
                    <TabList className={styles.tabList}>
                        <header>
                            <h2 className={styles.accountHeader}>
                                <Translation text={`form.consultationForm.account.${isFirstTabChosen ? 'registerHeading' : 'loginHeading'}`}/>
                            </h2>
                            <p className={styles.accountSubheader}>
                                <Translation text={`form.axaForm.account.subHeader`}/>
                            </p>
                            <p className={styles.accountSubheader}>
                                <Trans
                                    i18nKey={'form.axaForm.account.description'}
                                    components={[<span className={styles.description}></span>]}
                                />
                            </p>
                            <p className={styles.accountSubheader}>
                                <Translation text={`form.consultationForm.account.subHeader`}/>
                                <Tab className={styles.tab}
                                     selectedClassName={styles.selected}
                                     onClick={() => this.accountTabsHandleChange(0)}>
                                <span className={styles.multiTabItem}>
                                    <Translation text={`registerForm.title`}/>
                                </span>
                                </Tab>
                                <Tab className={styles.tab}
                                     selectedClassName={styles.selected}
                                     onClick={() => this.accountTabsHandleChange(1)}>
                                <span className={styles.multiTabItem}>
                                    <Translation text={`loginForm.info`}/>
                                </span>
                                </Tab>
                            </p>
                        </header>
                    </TabList>
                    <TabPanel className={styles.tabPanel} selectedClassName={styles.selected}>
                        <RegisterComponent/>
                    </TabPanel>
                    <TabPanel className={styles.tabPanel} selectedClassName={styles.selected}>
                        <LoginComponent/>
                    </TabPanel>
                </Tabs>
            </React.Fragment>
        )
    }

    private accountTabsHandleChange = (value: any) => {
        this.setState({
            accountCurrentTab: value
        });
    };
}

export default connect(
    (state: RootState) => ({
        isAuthenticated: isAuthenticatedSelector(state),
        isClinicHost: isClinicHostSelector(state),
        inquiryId: inquiryIdSelector(state),
        paymentToken: paymentTokenSelector(state)
    }),
    {
        changeCurrentStep,
    }
)(FormStepAccount);

