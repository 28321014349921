import {Observable} from "rxjs";
import {clinicHunterRegistrationAPI} from "./provider/clinicHunterRegistration";
import {RestQueryParams} from "common-web";

export function registerClinicAPI(
    login: string,
    plainPassword: string,
    firstName: string,
    lastName: string,
    birthdate: string,
    addressLine1: string,
    addressLine2: string,
    addressLine3: string,
    postcode: string,
    city: string,
    country: string,
    residency: string,
    reference: string
): Observable<any> {
    return clinicHunterRegistrationAPI.post(
        "register-clinic",
        {
            login: login,
            plainPassword: plainPassword,
            firstName: firstName,
            lastName: lastName,
            birthdate: birthdate,
            addressLine1: addressLine1,
            addressLine2: addressLine2 && addressLine2.length > 1 ? addressLine2 : '',
            addressLine3: addressLine3 && addressLine3.length > 1 ? addressLine3 : '',
            postcode: postcode,
            city: city,
            country: country,
            residency: residency,
            reference: reference
        },
        new RestQueryParams()
    );
}
