import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";
import {Observable} from "rxjs";
import {RestQueryParams} from "common-web";

export function getOnlineConsultationsApi(): Observable<any> {
    return clinicHunterInquiryAPI.get(
        "online_consultations",
        new RestQueryParams({page: 1}),
        {
            accept: 'application/ld+json',
            'content-type': 'application/json'
        },
    );
}

export function getOnlineConsultationAsLoggedAPI(inquiryId: string, accessToken: string): Observable<any> {
    return clinicHunterInquiryAPI.get(
        `online_consultations/${inquiryId}`,
        new RestQueryParams(),
        {
            accept: 'application/ld+json',
            'content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    );
}

export function getOnlineConsultationAPI(inquiryId: string,
                              credentials: {
                                  authToken: string | undefined,
                                  secret: string | undefined,
                              }): Observable<any> {
    let headers: any = {
        accept: 'application/ld+json',
        'content-type': 'application/json',
    };
    if (undefined !== credentials.authToken && credentials.authToken) {
        headers['Authorization'] = `Bearer ${credentials.authToken}`;
    } else if (undefined !== credentials.secret) {
        headers['X-Online-Consultation-Secret'] = credentials.secret;
    }

    return clinicHunterInquiryAPI.get(
        `online_consultation/${inquiryId}`,
        new RestQueryParams(),
        headers
    );
}
