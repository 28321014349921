import { createSelector } from "redux-starter-kit";
import { RootState } from "../reducers";
import { IAuthState } from "../reducers/authSlice";

export const selectAuth = (state: RootState): IAuthState => {
    return state.auth;
};

export const usernameSelector = createSelector(
    [selectAuth],
    (state: IAuthState) => state.username
);

export const authTokenSelector = createSelector(
    [selectAuth],
    (state: IAuthState) => state.authToken
);

export const refreshTokenSelector = createSelector(
    [selectAuth],
    (state: IAuthState) => state.refreshToken
);

export const isAuthenticatedSelector = createSelector(
    [selectAuth],
    (state: IAuthState) => state.isAuthenticated
);

export const secretSelector = createSelector(
    [selectAuth],
    (state: IAuthState) => state.secret
);

export const paymentTokenSelector = createSelector(
    [selectAuth],
    (state: IAuthState) => state.paymentToken
);
