import * as React from "react";
import {connect} from "react-redux";
import InquiryForm from "../../InquiryForm";
import {isClinicHostSelector, currentStepSelector} from "../../../store/selectors/formSelectors";
import {InquiryFormStep} from "../../InquiryForm/inquiryFormStep";
import {history} from '../../../index'

interface IClinicInquiryFormHostProps {
    readonly isClinicHost: boolean;
    readonly currentStep: string;
}

interface IClinicInquiryFormHostState {

}

class ClinicInquiryFormHost extends React.Component<IClinicInquiryFormHostProps, IClinicInquiryFormHostState> {
    history: any;

    constructor(props: any) {
        super(props);
        this.history = history;
        this.state = {};
    }

    componentDidMount(): void {
    }

    componentDidUpdate(prevProps: Readonly<IClinicInquiryFormHostProps>, prevState: Readonly<IClinicInquiryFormHostState>, snapshot?: any) {
        if (this.props.currentStep !== InquiryFormStep.SUMMARY) {
            window.parent.postMessage(null, '*')
        }
    }

    render() {
        return (
            <React.Fragment>
                <h1 className={'sr-only'}> Insurance inquiry form for Clinic</h1>
                <InquiryForm/>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: any) => ({
        isClinicHost: isClinicHostSelector(state),
        currentStep: currentStepSelector(state),
    })
)(ClinicInquiryFormHost);
