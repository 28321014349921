import { createSlice } from "redux-starter-kit";

export interface IMetaDataState {
  timezone: string | null;
  isLoading: boolean;
  error: string | null;
}

export interface IChangeMetadataLoading {
  readonly payload: {
    isLoading: boolean;
  };
}

export interface IChangeMetadataTimezone {
  readonly payload: {
    timezone: string;
  };
}

export interface ISetMetadataError {
  readonly payload: {
    error: string | null;
  };
}

export interface IChangeEventPlaceholder {
  readonly payload: {
    credentials: {
      authToken: string | null;
      secret: string | null;
    };
    validFrom: string;
    validUntil: string;
    startsAt: string;
    endsAt: string;
    subjectName: string;
    subjectId: string;
    calendarId: string;
  }
}

const initialState: IMetaDataState = {
    timezone: null,
    isLoading: false,
    error: null
};

const metadataSlice = createSlice({
  slice: "metadata",
  initialState: initialState,
  reducers: {
    changeEventPlaceholder: {
      reducer: (state: IMetaDataState, action: IChangeEventPlaceholder) => {
        return {
          timezone: state.timezone,
          isLoading: state.isLoading,
          error: state.error
        }
      },
      prepare(
        credentials: {
          authToken: string | null,
          secret: string | null,
        },
        validFrom: string,
        validUntil: string,
        startsAt: string,
        endsAt: string,
        subjectName: string,
        subjectId: string,
        calendarId: string
      ) {
        return {
          payload: {
            credentials: {
              authToken: credentials.authToken,
              secret: credentials.secret
            },
            validFrom: validFrom,
            validUntil: validUntil,
            startsAt: startsAt,
            endsAt: endsAt,
            subjectName: subjectName,
            subjectId: subjectId,
            calendarId: calendarId
          }
        };
      }
    },
    changeMetadataTimezone: {
      reducer: (state: IMetaDataState, action: IChangeMetadataTimezone) => {
        return {
          timezone: action.payload.timezone,
          isLoading: state.isLoading,
          error: state.error
        }
      },
      prepare(timezone: string) {
        return {
          payload: { timezone: timezone }
        };
      }
    },
    changeMetadataLoading: {
      reducer: (state: IMetaDataState, action: IChangeMetadataLoading) => {
        return {
          timezone: state.timezone,
          isLoading: action.payload.isLoading,
          error: state.error
        }
      },
      prepare(isLoading: boolean) {
        return {
          payload: { isLoading: isLoading }
        };
      }
    },
    setMetadataError: {
      reducer: (state: IMetaDataState, action: ISetMetadataError) => {
        return {
          timezone: state.timezone,
          isLoading: state.isLoading,
          error: action.payload.error
        }
      },
      prepare(error: string) {
        return {
          payload: { error: error }
        };
      }
    }
  }
});

export const {
  changeEventPlaceholder,
  changeMetadataLoading,
  changeMetadataTimezone,
  setMetadataError
} = metadataSlice.actions;
export default metadataSlice.reducer;
