import { createSelector } from "redux-starter-kit";
import { RootState } from "../reducers";
import { IFormState } from "store/reducers/formSlice";

export const selectForm = (state: RootState): IFormState => {
    return state.form;
};

export const currentStepSelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.currentStep
);

export const formStepsSelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.formSteps
);

export const formSelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.inquiryForm
);

export const isInquiryCreatedSelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.isInquiryCreated
);

export const inquiryIdSelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.inquiryId
);

export const inquiryPaymentIdSelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.inquiryPaymentId
);


export const resetInvalidFormInputSelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.inquiryForm
);

export const showFormLoaderSelector = createSelector(
    [selectForm],
    (axaInsuranceState: IFormState) => axaInsuranceState.showFormLoader
);

export const isFormInitializedSelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.initialized
);

export const wizardHeadStepIndexSelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.wizardHeadStepIndex
);

export const isFormUpdatedSelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.isInquiryFormUpdated
);

export const inquirySelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.inquiry
);

export const isClinicHostSelector = createSelector(
    [selectForm],
    (formState: IFormState) => formState.isClinicHost
);
