import React from 'react';
import './App.scss';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import FormHost from "./components/FormHost";
import {Route, Switch} from "react-router";
import ClinicFormHost from "./components/ClinicFormHost";

interface IAppProps extends RouteComponentProps {
}

class App extends React.Component<IAppProps> {

    render() {
        return (
            <div className="App">
                <Switch>
                    <Route path={`/c/`} component={ClinicFormHost}/>
                    <Route path={`/`} component={FormHost}/>
                </Switch>
            </div>
        );
    }
}

export default withRouter(App);
