import {clinicHunterInquiryAPI} from "./provider/clinicHunterInquiry";
import {Observable} from "rxjs";
import {RestQueryParams} from "common-web";
import {IOnlineConsultationParticipant} from "./changeOnlineConsultation";

export function createConsultationAPI(
    credentials: {
        authToken: string | undefined,
        secret: string | undefined,
    },
    clinicId: string | null,
    onlineConsultationParticipants: IOnlineConsultationParticipant[],
    startsAt: string,
    notes: string | null,
    onlineConsultationId: string,
    calendarId: string,
    paymentToken: string | null,
): Observable<any> {
    const payload: any = {
      clinic: clinicId,
      onlineConsultationParticipants: onlineConsultationParticipants,
      startsAt: startsAt,
      notes: notes,
      onlineConsultationId: onlineConsultationId,
      calendar: calendarId
    };

    let headers: {[key: string]: any} = {};
    if (paymentToken) {
        headers['X-Payment-Token'] = paymentToken;
        payload['byInvitation'] = true;
    }

    if (undefined !== credentials.authToken) {
        headers['Authorization'] = `Bearer ${credentials.authToken}`;
    } else {
        payload.secret = credentials.secret;
    }

    return clinicHunterInquiryAPI.post(
        "online_consultation",
        payload,
        new RestQueryParams(),
        headers
    );
}
