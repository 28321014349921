import * as React from "react";
import {Form, Translation, FormControlChangeType, IFormConfig} from 'common-web';
import {connect} from "react-redux";
import {RootState} from "../../../store/reducers";
import {formSelector} from "../../../store/selectors/formSelectors";
import {changeCurrentStep, changeForm, IInquiryForm} from "../../../store/reducers/formSlice";
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {patientFormConfig} from "./patientFormConfig";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {InquiryFormStep} from '../inquiryFormStep';
import {IStepManagerService} from '../../../service/stepManagerService';
import {BehaviorSubject, Subscription} from "rxjs";
import {tap, filter, debounceTime} from "rxjs/operators";
import {IFormConfigTranslationService} from "../../../service/formConfigTranslationService";

interface IFormStepPatientProps extends RouteComponentProps {
    readonly inquiryForm: IInquiryForm;
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly changeForm: typeof changeForm;
}

interface IFormStepPatientState {
    formConfig: typeof IFormConfig;
    value: any;
    isFormValid: boolean;
}

class FormStepPatient extends React.Component<IFormStepPatientProps, IFormStepPatientState> {
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;
    @lazyInject('FormConfigTranslationService') private formConfigTranslator: IFormConfigTranslationService;


    readonly stepName: InquiryFormStep = InquiryFormStep.PATIENT;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    private isProcessing: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            formConfig: patientFormConfig,
            value: null,
            isFormValid: true,
        };
        fixInjectedProperties(this);
    }

    componentDidMount() {
        const translatedFormConfig = this.formConfigTranslator.setTranslationKeys(this.state.formConfig, this.stepName);
        this.setState({formConfig: translatedFormConfig});
        this.props.changeCurrentStep(this.stepName);
        if (this.props.inquiryForm) {
            this.updateFormFromState();
        }

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap(()  => this.isProcessing = true),
                debounceTime(500),
                tap((data: any)  => this.onFormValueChange(data.value)),
                tap(()  => this.isProcessing = false),
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<IFormStepPatientProps>,
        prevState: Readonly<IFormStepPatientState>,
        snapshot?: any
    ): void {
        if (this.props.inquiryForm !== prevProps.inquiryForm) {
            this.updateFormFromState();
        }
    }

    componentWillUnmount(): void {
    }

    render() {
        return (
            <React.Fragment>
                <header>
                    <h2 className={'sr-only'}><Translation text={`form.consultationForm.patient.title`}/></h2>
                </header>
                <article>
                    <header>
                        <h3 className={'sr-only'}><Translation text={`form.consultationForm.patient.subtitle`}/>
                        </h3>
                    </header>
                    <Form config={this.state.formConfig}
                          onValueStateChange={this.onValueStateChange}
                          onValidationStateChange={this.onValidationStateChange}
                          value={this.state.value}
                          controlName={'patientForm'}/>

                    <footer className="button-form-container">
                        <button onClick={this.goPrev}
                                className="btn btn-prev">
                            <Translation text={`form.buttons.prev`}/>
                        </button>

                        <button className={`btn btn-next ${!this.state.isFormValid ? 'disabled' : ''}`}
                                tabIndex={!this.state.isFormValid ? -1 : 0}
                                onClick={this.goNext}>
                            <Translation text={`form.buttons.next`}/>
                        </button>
                    </footer>
                </article>
            </React.Fragment>
        )
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private goNext = () => {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        this.stepManager.goNext(this.stepName)
    };

    private goPrev = () => {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        this.stepManager.goPrev(this.stepName)
    };

    // ========== CUSTOM METHODS ========== //


    private onFormValueChange = (value: any) => {
        this.props.changeForm(value);
    };

    private updateFormFromState = () => {
        const inquiry = this.props.inquiryForm;

        const value: any = {
            name: inquiry.name,
            lastName: inquiry.lastName,
            additionalComments: inquiry.additionalComments,
        };

        this.setState({
            value: value,
        });
    };
}


export default connect(
    (state: RootState) => ({
        inquiryForm: formSelector(state),
    }),
    {
        changeCurrentStep,
        changeForm,
    })(withRouter(FormStepPatient));
