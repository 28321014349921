import {createSlice, PayloadAction} from "redux-starter-kit";

export interface IAuthEntry {
  username: string | null;
  authToken: string | null;
  refreshToken: string | null;
  secret: string | null;
}

export interface IAuthTokens {
  authToken: string;
  refreshToken: string;
}

export interface IAuthState {
  username: string | null;
  authToken: string | null;
  refreshToken: string | null;
  secret: string | null;
  paymentToken: string | null;
  error: string | null;
  isAuthenticated: boolean;
}

export interface ISetAuthTokens {
  readonly authToken: string | null;
  readonly refreshToken: string | null;
}

export interface ISetSecret {
  readonly secret: string | null;
}

export interface ISetPaymentToken {
  readonly paymentToken: string | null;
}

export interface IGetLoginCredentials {
  username: string;
  password: string;
}

export interface ISetAuthState {
  readonly username: string | null;
  readonly authToken: string | null;
  readonly refreshToken: string | null;
  readonly secret: string | null;
  readonly paymentToken: string | null;
  readonly isAuthenticated: boolean;
}

export interface ISetAuthError {
  readonly error: string;
}

export interface IChangeIsAuthenticated {
  readonly isAuthenticated: boolean;
}

export interface IResetPasswordStart {
  readonly email: string;
  readonly redirectUrl: string;
}

export interface IRegisterStart {
  readonly email: string;
  readonly password: string;
  readonly redirectUrl: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly birthdate: string;
  readonly addressLine1: string;
  readonly addressLine2: string;
  readonly addressLine3: string;
  readonly postcode: string;
  readonly city: string;
  readonly country: string;
  readonly residency: string;
  readonly reference: string;
}

export interface IConfirmRegistrationStart {
  readonly id: string;
}

export interface IConfirmResetPasswordStart {
  readonly password: string;
  readonly confirmationToken: string;
}

export interface IRenewAuthToken {
  readonly refreshToken: string;
}

const initialState: IAuthState = {
  username: null,
  authToken: null,
  refreshToken: null,
  secret: null,
  paymentToken: null,
  error: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  slice: "auth",
  initialState: initialState,
  reducers: {
    setAuthState: {
      reducer: (state: IAuthState, action: PayloadAction<ISetAuthState>) => {
       return {
          username: action.payload.username,
          authToken: action.payload.authToken,
          refreshToken: action.payload.refreshToken,
          secret: action.payload.secret,
          paymentToken: action.payload.paymentToken,
          error: state.error,
          isAuthenticated: action.payload.isAuthenticated,
       };
      },
      prepare(
          username: string | null,
          authToken: string | null,
          refreshToken: string | null,
          secret: string | null,
          paymentToken: string | null,
          isAuthenticated: boolean,
      ) {
        return {
          payload: {
            username: username,
            authToken: authToken,
            refreshToken: refreshToken,
            secret: secret,
            paymentToken: paymentToken,
            isAuthenticated: isAuthenticated,
          }
        };
      }
    },
    setAuthStateFailure: {
      reducer: (state: IAuthState, action: PayloadAction<ISetAuthError>) => {
        return {
          username: state.username,
          authToken: state.authToken,
          refreshToken: state.refreshToken,
          secret: state.secret,
          paymentToken: state.paymentToken,
          error: action.payload.error,
          isAuthenticated: state.isAuthenticated,
        }
      },
      prepare(error: string) {
        return {
          payload: {
            error: error
          }
        }
      }
    },
    setAuthTokens: {
      reducer: (state: IAuthState, action: PayloadAction<ISetAuthTokens>) => {
        return {
          username: state.username,
          authToken: action.payload.authToken,
          refreshToken: action.payload.refreshToken,
          secret: state.secret,
          paymentToken: state.paymentToken,
          error: state.error,
          isAuthenticated: state.isAuthenticated
        }
      },
      prepare(authToken: string, refreshToken: string) {
        return {
          payload: {
            authToken: authToken,
            refreshToken: refreshToken,
          }
        };
      }
    },
    setSecret: {
      reducer: (state: IAuthState, action: PayloadAction<ISetSecret>) => {
        return {
          username: state.username,
          authToken: state.authToken,
          refreshToken: state.refreshToken,
          secret: action.payload.secret,
          paymentToken: state.paymentToken,
          error: state.error,
          isAuthenticated: state.isAuthenticated,
        }
      },
      prepare(secret: string | null) {
        return {
          payload: {
            secret: secret
          }
        };
      }
    },
    setPaymentToken: {
      reducer: (state: IAuthState, action: PayloadAction<ISetPaymentToken>) => {
        return {
          username: state.username,
          authToken: state.authToken,
          refreshToken: state.refreshToken,
          secret: state.secret,
          paymentToken: action.payload.paymentToken,
          error: state.error,
          isAuthenticated: state.isAuthenticated,
        }
      },
      prepare(paymentToken: string | null) {
        return {
          payload: {
            paymentToken: paymentToken
          }
        };
      }
    },
    getLoginCredentials: {
      reducer: (state: IAuthState, action: PayloadAction<IGetLoginCredentials>) => {
        return {
          username: action.payload.username,
          authToken: state.authToken,
          refreshToken: state.refreshToken,
          secret: state.secret,
          paymentToken: state.paymentToken,
          error: state.error,
          isAuthenticated: state.isAuthenticated,
        }
      },
      prepare(username: string, password: string) {
        return {
          payload: {
            username: username,
            password: password,
          }
        };
      }
    },
    changeIsAuthenticated: {
      reducer: (state: IAuthState, action: PayloadAction<IChangeIsAuthenticated>) => {
        return {
          username: state.username,
          authToken: state.authToken,
          refreshToken: state.refreshToken,
          secret: state.secret,
          paymentToken: state.paymentToken,
          error: state.error,
          isAuthenticated: action.payload.isAuthenticated,
        }
      },
      prepare(isAuthenticated: boolean) {
        return {
          payload: {
            isAuthenticated: isAuthenticated,
          }
        };
      }
    },
    renewAuthToken: {
      reducer: (state: IAuthState, action: PayloadAction<IRenewAuthToken>) => {
        return {
          username: state.username,
          authToken: state.authToken,
          refreshToken: state.refreshToken,
          secret: state.secret,
          paymentToken: state.paymentToken,
          error: state.error,
          isAuthenticated: state.isAuthenticated,
        }
      },
      prepare(refreshToken: string, authToken: string) {
        return {
          payload: {
            authToken: authToken,
            refreshToken: refreshToken
          }
        };
      }
    },
    resetPasswordStart: {
      reducer: (state: IAuthState, action: PayloadAction<IResetPasswordStart>) => {
        return {
          username: state.username,
          authToken: state.authToken,
          refreshToken: state.refreshToken,
          secret: state.secret,
          paymentToken: state.paymentToken,
          error: state.error,
          isAuthenticated: state.isAuthenticated,
        }
      },
      prepare(email: string, redirectUrl: string) {
        return {
          payload: {
            email: email,
            redirectUrl: redirectUrl,
          }
        };
      }
    },
    registerStart: {
      reducer: (state: IAuthState, action: PayloadAction<IRegisterStart>) => {
        return {
          username: state.username,
          authToken: state.authToken,
          refreshToken: state.refreshToken,
          secret: state.secret,
          paymentToken: state.paymentToken,
          error: state.error,
          isAuthenticated: state.isAuthenticated,
        }
      },
      prepare(email: string,
              password: string,
              redirectUrl: string,
              firstName: string,
              lastName: string,
              birthdate: string,
              addressLine1: string,
              addressLine2: string,
              addressLine3: string,
              postcode: string,
              city: string,
              country: string,
              residency: string,
              reference: string) {
        return {
          payload: {
            email: email,
            password: password,
            redirectUrl: redirectUrl,
            firstName: firstName,
            lastName: lastName,
            birthdate: birthdate,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            addressLine3: addressLine3,
            postcode: postcode,
            city: city,
            country: country,
            residency: residency,
            reference: reference,
          }
        };
      }
    },
    confirmRegistrationStart: {
      reducer: (state: IAuthState, action: PayloadAction<IConfirmRegistrationStart>) => {
        return {
          username: state.username,
          authToken: state.authToken,
          refreshToken: state.refreshToken,
          secret: state.secret,
          paymentToken: state.paymentToken,
          error: state.error,
          isAuthenticated: state.isAuthenticated,
        }
      },
      prepare(id: string) {
        return {
          payload: {
            id: id
          }
        };
      }
    },
    confirmResetPasswordStart: {
      reducer: (state: IAuthState, action: PayloadAction<IConfirmResetPasswordStart>) => {
        return {
          username: state.username,
          authToken: state.authToken,
          refreshToken: state.refreshToken,
          secret: state.secret,
          paymentToken: state.paymentToken,
          error: state.error,
          isAuthenticated: state.isAuthenticated,
        }
      },
      prepare(password: string, confirmationToken: string) {
        return {
          payload: {
            password: password,
            confirmationToken: confirmationToken
          }
        };
      }
    },
  }
});

export const {
  setAuthState,
  setAuthTokens,
  getLoginCredentials,
  setAuthStateFailure,
  changeIsAuthenticated,
  resetPasswordStart,
  registerStart,
  confirmRegistrationStart,
  confirmResetPasswordStart,
  setSecret,
  setPaymentToken,
  renewAuthToken,
} = authSlice.actions;

export default authSlice.reducer;
