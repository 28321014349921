import * as React from "react";
import {connect} from "react-redux";
import {changeCurrentStep} from '../../../store/reducers/formSlice';
import {InquiryFormStep} from '../inquiryFormStep';
import CheckoutForm from './CheckoutForm';

interface IFormStepPaymentProps {
    readonly changeCurrentStep: typeof changeCurrentStep;
}

class IFormStepPaymentState {
}

class FormStepPayment extends React.Component<IFormStepPaymentProps, IFormStepPaymentState> {

    componentDidMount() {
        this.props.changeCurrentStep(InquiryFormStep.PAYMENT);
    }

    render() {
        return (
            <CheckoutForm />
        )
    }
}

export default connect(
    () => ({}),
    {
        changeCurrentStep,
    }
)(FormStepPayment);

