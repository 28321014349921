import * as React from "react";
import {Translation} from 'common-web';
import {connect} from "react-redux";
import styles from "./styles.module.scss";
import {InquiryFormStep} from "../inquiryFormStep";
import {changeCurrentStep, resetFormAndInitialize} from "../../../store/reducers/formSlice";

interface IFormStepSuccessProps {
    readonly changeCurrentStep: typeof changeCurrentStep;
    readonly resetFormAndInitialize: typeof resetFormAndInitialize;
}

interface IFormStepSuccessState {}

class FormStepSuccess extends React.Component<IFormStepSuccessProps, IFormStepSuccessState> {

    componentDidMount() {
        this.props.changeCurrentStep(InquiryFormStep.SUCCESS);
    }

    componentWillUnmount() {
        this.props.resetFormAndInitialize();
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles.successPageWrapper}>
                    <div className={styles.completedIcon}>
                        <div className={styles.checked}/>
                    </div>
                    <div className={styles.successPageContent}>
                        <h2 className={styles.successTitle}>
                            <Translation text={`form.consultationForm.successPage.title`}/>
                        </h2>
                        <p className={styles.successSubTitle}>
                            <Translation text={`form.consultationForm.successPage.consultation`}/>
                        </p>
                        <p className={styles.successSubTitle}>
                            <Translation text={`form.consultationForm.successPage.details`}/>
                        </p>
                        <div className={styles.buttonWrapper}>
                            <button type="button" name="forward"
                                    className="btn btn-primary"
                                    onClick={() => this.goToClinicHunter()}>
                                <Translation text={`form.buttons.backToClinic`}/>
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    goToClinicHunter = () => {
        window.location.href = 'https://clinichunter.com/';
    };
}

export default connect(
    () => ({}),
    {
        changeCurrentStep,
        resetFormAndInitialize
    }
)(FormStepSuccess);
