const en = {
  additionalInformation: {
    heading: "Medical travel insurance configurator form",
    title: "Clinic Hunter Cover",
    subtitle: "medical travel insurance",
    howItWorks: "how it works",
    aboutUs: "about us",
    terms: "terms & conditions",
    claims: "claims",
    faq: "faq",
  },
  form: {
    buttons: {
      next: "Next",
      prev: "Prev",
      buy: "Buy now",
      backToEdit: "Go back to edit",
      backToClinic: "Back to clinic hunter",
    },
    consultationForm: {
      treatment: {
        title: "Treatment",
        formControls: {
          treatmentCategoryId: {
            label: "What treatment are you undertaking?",
            placeholder: 'Select Treatment'
          },
          consultationDate: {
            label: "Choose date",
            placeholder: 'Select Treatment',
          },
        }
      },
      patient: {
        title: "Patient",
        subtitle: "Patient data form",
        formControls: {
          name: {
            label: "What is your name?",
            placeholder: 'Name'
          },
          lastName: {
            label: "What is your surname?",
            placeholder: 'Surname'
          },
          additionalComments: {
            label: `Do you have additional comments?`,
            placeholder: 'For example, I am looking for a tummy tuck or liposuction on abdomen and also to fix the skin under my chin'
          }
        }
      },
      summary: {
        title: "Summary",
        clinic: {
          label: 'Clinic'
        },
        treatment: {
          label: 'Treatment consultation'
        },
        consultationDate: {
          label: 'Date & time'
        },
        duration: {
          label: 'Duration'
        },
        patientName: {
          label: 'Patient name'
        },
        comment: {
          label: 'Comment'
        },
        totalPrice: {
          label: 'Total price'
        },
      },
      account: {
        title: "Account",
        registerHeading: "Create an account",
        loginHeading: "Login into account",
        subHeader: "Congratulations! Your insurance has been almost completed. Please provide us with your email address to set an account.",
        description: `<0>Why do I need an account?</0> On your account you will be able to access your policy details and make adjustments if needed.`,
      },
      payment: {
        title: "Payment",
        formControls: {
          cardholderName: {
            label: "Cardholder Name:",
            placeholder: 'Cardholder name'
          },
          stripecard: {
            label: 'Provide your card details',
            placeholder: ''
          },
          termsAndConditions: {
            checkboxLabel: `Before concluding the insurance contract I received the <a href="https://clinichunter.com/privacy-policy/" target="_blank">Special Terms and Conditions of Travel Insurance for ClinicHunter clients</a> and <a href="https://clinichunter.com/privacy-policy/" target="_blank">Information Brochure Concerning Personal Data Process</a> and undertake to familiarize all insured persons with its content`
          },
        }
      },
      successPage: {
        title: "Thank you!",
        consultation: "Your visit has been booked successfully.",
        details: "Check your email. You will receive information with details of the visit and a link to the video consultation."
      }
    },
    clinicTelemedicineForm: {
      treatment: {
        title: "Treatment",
        formControls: {
          treatmentCategoryId: {
            label: "What treatment is your Patient undertaking?",
            placeholder: 'Select Treatment'
          },
          consultationDate: {
            label: "Choose date",
            placeholder: 'Select Treatment',
          },
        }
      },
      patient: {
        title: "Patient",
        subtitle: "Patient data form",
        formControls: {
          name: {
            label: "What is your Patient's name?",
            placeholder: 'Name & surname'
          },
          // lastName: {
          //   label: "What is your surname?",
          //   placeholder: 'Surname'
          // },
          additionalComments: {
            label: `Do you have additional comments?`,
            placeholder: 'For example, I am looking for a tummy tuck or liposuction on abdomen and also to fix the skin under my chin'
          },
        }
      },
      summary: {
        title: "Summary",
        clinic: {
          label: 'Clinic'
        },
        treatment: {
          label: 'Treatment consultation'
        },
        consultationDate: {
          label: 'Date & time'
        },
        duration: {
          label: 'Duration'
        },
        patientName: {
          label: 'Patient name'
        },
        comment: {
          label: 'Comment'
        },
        totalPrice: {
          label: 'Total price'
        },
      },
      account: {
        title: "Account",
        registerHeading: "Create an account",
        loginHeading: "Login into account",
        subHeader: "Congratulations! Your insurance has been almost completed. Please provide us with your email address to set an account.",
        description: `<0>Why do I need an account?</0> On your account you will be able to access your policy details and make adjustments if needed.`,
      },
      payment: {
        title: "Payment",
        formControls: {
          cardholderName: {
            label: "Cardholder Name:",
            placeholder: 'Cardholder name'
          },
          stripecard: {
            label: 'Provide your card details',
            placeholder: ''
          },
          termsAndConditions: {
            checkboxLabel: `Before concluding the insurance contract I received the <a href="https://clinichunter.com/privacy-policy/" target="_blank">Special Terms and Conditions of Travel Insurance for ClinicHunter clients</a> and <a href="https://clinichunter.com/privacy-policy/" target="_blank">Information Brochure Concerning Personal Data Process</a> and undertake to familiarize all insured persons with its content`
          },
        }
      },
      successPage: {
        subTitle: "Your visit in was booked successfully.",
        details: "Lorem ipsum dolor sit amet, consectetur adipiding elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." +
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea a commodo consequat."
      }
    }
  },
  formValidation: {
    errors: {
      minLength: "The input is too short",
      isEmailValid: "Provide a valid email address",
      isRequired: "The field is required",
      isTrue: "Must be checked",
      isPasswordSame: "Passwords not match",
      isStartDateValid: "Date must be after current day",
      isStartDateGreaterOrEvenValid: "Date must be at or after current day",
      isEndDateValid: "Date of return must be after departure",
      sameEmail: 'The emails should be the same',
      samePassword: 'The passwords should be the same'
    }
  },
  loginForm: {
    title: "Login",
    info: 'I already have an account',
    userName: "Login or email address",
    password: "Password",
    buttons: {
      login: "Login",
      logout: "Logout",
      dashboard: "Patient Dashboard",
      profile: "Profile",
      messages: "My messages",
      menuButtonNotLogged: "Sign in",
      menuButtonLogged: "Account Menu"
    },
     formControls: {
       loginEmail: {
         label: "Email address",
         placeholder: "Email address",
       },
       loginPassword: {
         label: "Password",
         placeholder: "Password",
       }
    }
  },
  registerUserForm: {
    title: "Registration"
  },
  registerForm: {
    title: 'I want to create one',
    buttons: {
      register: 'Register'
    },
    formControls: {
      registrationEmail: {
        placeholder: "example@email.com",
        label: "Enter your email",
      },
      registrationPassword: {
        placeholder: "Type password",
        label: "Your password",
      },
      registrationPasswordConfirm: {
        placeholder: "Type password",
        label: "Confirm your password",
      },
      registrationTermsAgreement: {
        checkboxLabel: `I agree to <a href="https://clinichunter.com/privacy-policy/" target="_blank">Privacy Policy</a>, <a href="https://clinichunter.com/personal-data-protection-policy/" target="_blank">personal data usage</a> and <a href="https://clinichunter.com/personal-data-information-clause-art-13-gdpr/" target="_blank">art. 13 GDPR</a>*`,
      }
    }
  }
};

export default en;
