import {Observable} from "rxjs";
import {IAuthEntry} from "../store/reducers/authSlice";
import {clinicHunterAuthAPI} from "./provider/clinicHunterAuth";
import {RestQueryParams} from "common-web";

export function loginAPI(
    username: string,
    password: string
): Observable<IAuthEntry> {
    return clinicHunterAuthAPI.post(
        "login_check",
        {
            username: username,
            password: password
        },
        new RestQueryParams()
    );
}
