import {createSlice, PayloadAction} from "redux-starter-kit";
import {InquiryFormStep} from '../../components/InquiryForm/inquiryFormStep';
import {stepsNotAuthenticated} from '../../components/FormHost/formSteps';

export interface IInquiryForm {
    onlineConsultationId: string | null;
    calendarId: string | null;
    consultationDate: string | null;
    cid: string | null;
    name: string | null;
    lastName: string | null;
    additionalComments: string | null;
    cardholderName: string | null;
    loginEmail: string | null;
    loginPassword: string | null;
    registrationEmail: string | null;
    registrationPassword: string | null;
    registrationPasswordConfirm: string | null;
    registrationTermsAgreement: boolean | null;
    termsAndConditions: boolean | null;
    quote: string |null;
}

export interface IFormState {
    initialized: boolean,
    formSteps: InquiryFormStep[],
    inquiryForm: IInquiryForm;
    inquiryId: string | null;
    inquiryPaymentId: string | null;
    inquiry: any;
    currentStep: InquiryFormStep;
    isInquiryCreated: boolean;
    isClinicHost: boolean;
    isInquiryFormUpdated: boolean;
    wizardHeadStepIndex: number;

    showFormLoader: boolean,
}

export interface IChangeFormSteps {
    readonly payload: {
        formSteps: InquiryFormStep[];
    };
}

export interface IChangeCurrentStep {
    readonly payload: {
        currentStep: InquiryFormStep;
    };
}

export interface IChangeIsClinicHost {
        isClinicHost: boolean;
}

export interface IChangeInquiryId {
    readonly payload: {
        inquiryId: string;
    };
}

export interface IChangeInquiryPaymentId {
    readonly payload: {
        inquiryPaymentId: string;
    };
}

export interface IChangeForm {
    readonly payload: {
        inquiryForm: IInquiryForm;
    };
}

export interface IChangeShowFormLoader {
    readonly payload: {
        showFormLoader: boolean;
    };
}

export interface IChangeWizardHeadStepIndex {
    readonly payload: {
        wizardHeadStepIndex: number;
    };
}

export interface IChangeIsInquiryFormUpdated {
    readonly payload: {
        isInquiryFormUpdated: boolean
    }
}
export interface IChangeInquiry {
    readonly payload: {
        inquiry: any
    }
}

const initialState: IFormState = {
    initialized: false,
    formSteps: stepsNotAuthenticated,
    inquiryForm: {
        onlineConsultationId: null,
        calendarId: null,
        consultationDate: null,
        cid: null,
        name: null,
        lastName: null,
        additionalComments: null,
        cardholderName: null,
        loginEmail: null,
        loginPassword: null,
        registrationEmail: null,
        registrationPassword: null,
        registrationPasswordConfirm: null,
        registrationTermsAgreement: null,
        termsAndConditions: null,
        quote: null
    },
    currentStep: InquiryFormStep.TREATMENT,
    inquiryId: null,
    inquiryPaymentId: null,
    inquiry: null,
    isInquiryCreated: false,
    isClinicHost: false,
    isInquiryFormUpdated: false,
    wizardHeadStepIndex: 0,

    showFormLoader: false,
};

const formSlice = createSlice({
    slice: "form",
    initialState: initialState,
    reducers: {
        changeFormSteps: {
            reducer: (state: IFormState, action: IChangeFormSteps) => {
                return {
                    initialized: state.initialized,
                    formSteps: action.payload.formSteps,
                    inquiryForm: state.inquiryForm,
                    currentStep: state.currentStep,
                    inquiryId: state.inquiryId,
                    inquiryPaymentId: state.inquiryPaymentId,
                    inquiry: state.inquiry,
                    isInquiryCreated: state.isInquiryCreated,
                    isClinicHost: state.isClinicHost,
                    isInquiryFormUpdated: state.isInquiryFormUpdated,
                    wizardHeadStepIndex: state.wizardHeadStepIndex,

                    showFormLoader: state.showFormLoader,
                };
            },
            prepare(formSteps: InquiryFormStep[]) {
                return {
                    payload: { formSteps: formSteps }
                };
            }
        },
        changeForm: {
            reducer: (state: IFormState, action: IChangeForm) => {
                const actionInquiryForm: any = action.payload.inquiryForm;
                const inquiryForm: any = {};
                let isChanged = false;
                let currentStepHeadNo;
                (Object.keys(initialState.inquiryForm) as any).map((key: keyof IInquiryForm) => {
                    if (actionInquiryForm[key] || actionInquiryForm[key] === false) {
                        isChanged = true;
                        return inquiryForm[key] = actionInquiryForm[key]
                    }

                    // in case the quote is generated and information in Treatment or Patient tab is changed, disable Account and Payment tabs
                    // so that user has to confirm the updated quote
                    if (state.currentStep === InquiryFormStep.TREATMENT || state.currentStep === InquiryFormStep.PATIENT) {
                        if (isChanged) {
                            if (state.wizardHeadStepIndex > 2) {
                                currentStepHeadNo = 2;
                            }
                        }
                    }
                    return inquiryForm[key] = state.inquiryForm[key];
                });

                return {
                    initialized: state.initialized,
                    formSteps: state.formSteps,
                    inquiryForm: inquiryForm,
                    currentStep: state.currentStep,
                    inquiryId: state.inquiryId,
                    inquiryPaymentId: state.inquiryPaymentId,
                    inquiry: state.inquiry,
                    isInquiryCreated: state.isInquiryCreated,
                    isClinicHost: state.isClinicHost,
                    isInquiryFormUpdated: isChanged,
                    wizardHeadStepIndex: currentStepHeadNo ? currentStepHeadNo : state.wizardHeadStepIndex,

                    showFormLoader: state.showFormLoader,
                };
            },
            prepare(inquiryForm: IInquiryForm) {
                return {
                    payload: {inquiryForm: inquiryForm}
                };
            }
        },
        changeCurrentStep: {
            reducer: (state: IFormState, action: IChangeCurrentStep) => {
                return {
                    initialized: state.initialized,
                    formSteps: state.formSteps,
                    inquiryForm: state.inquiryForm,
                    currentStep: action.payload.currentStep,
                    inquiryId: state.inquiryId,
                    inquiryPaymentId: state.inquiryPaymentId,
                    inquiry: state.inquiry,
                    isInquiryCreated: state.isInquiryCreated,
                    isClinicHost: state.isClinicHost,
                    isInquiryFormUpdated: state.isInquiryFormUpdated,
                    wizardHeadStepIndex: state.wizardHeadStepIndex,

                    showFormLoader: state.showFormLoader,
                };
            },
            prepare(currentStep: InquiryFormStep) {
                return {
                    payload: { currentStep: currentStep }
                };
            }
        },
        changeInquiryId: {
            reducer: (state: IFormState, action: IChangeInquiryId) => {

                return {
                    initialized: state.initialized,
                    formSteps: state.formSteps,
                    inquiryForm: state.inquiryForm,
                    currentStep: state.currentStep,
                    inquiryId: action.payload.inquiryId,
                    inquiryPaymentId: state.inquiryPaymentId,
                    inquiry: state.inquiryId,
                    isInquiryCreated: state.isInquiryCreated,
                    isClinicHost: state.isClinicHost,
                    isInquiryFormUpdated: state.isInquiryFormUpdated,
                    wizardHeadStepIndex: state.wizardHeadStepIndex,
                    showFormLoader: state.showFormLoader,
                };
            },
            prepare(inquiryId: string) {
                return {
                    payload: {inquiryId: inquiryId}
                };
            }
        },
        changeInquiryPaymentId: {
            reducer: (state: IFormState, action: IChangeInquiryPaymentId) => {

                return {
                    initialized: state.initialized,
                    formSteps: state.formSteps,
                    inquiryForm: state.inquiryForm,
                    currentStep: state.currentStep,
                    inquiryId: state.inquiryId,
                    inquiryPaymentId: action.payload.inquiryPaymentId,
                    inquiry: state.inquiry,
                    isInquiryCreated: state.isInquiryCreated,
                    isClinicHost: state.isClinicHost,
                    isInquiryFormUpdated: state.isInquiryFormUpdated,
                    wizardHeadStepIndex: state.wizardHeadStepIndex,
                    showFormLoader: state.showFormLoader,
                };
            },
            prepare(inquiryPaymentId: string) {
                return {
                    payload: {inquiryPaymentId: inquiryPaymentId}
                };
            }
        },
        changeInquiry: {
            reducer: (state: IFormState, action: IChangeInquiry) => {
                return {
                    initialized: state.initialized,
                    formSteps: state.formSteps,
                    inquiryForm: state.inquiryForm,
                    currentStep: state.currentStep,
                    inquiryId: state.inquiryId,
                    inquiryPaymentId: state.inquiryPaymentId,
                    inquiry: action.payload.inquiry,
                    isInquiryCreated: state.isInquiryCreated,
                    isClinicHost: state.isClinicHost,
                    isInquiryFormUpdated: state.isInquiryFormUpdated,
                    wizardHeadStepIndex: state.wizardHeadStepIndex,

                    showFormLoader: state.showFormLoader,
                };
            },
            prepare(inquiry: any) {
                return {
                    payload: {inquiry: inquiry}
                };
            }
        },
        changeIsClinicHost: {
            reducer: (state: IFormState, action: PayloadAction<IChangeIsClinicHost>) => {
                return {
                    initialized: state.initialized,
                    formSteps: state.formSteps,
                    inquiryForm: state.inquiryForm,
                    currentStep: state.currentStep,
                    inquiryId: state.inquiryId,
                    inquiryPaymentId: state.inquiryPaymentId,
                    inquiry: state.inquiry,
                    isInquiryCreated: state.isInquiryCreated,
                    isClinicHost: action.payload.isClinicHost,
                    isInquiryFormUpdated: state.isInquiryFormUpdated,
                    wizardHeadStepIndex: state.wizardHeadStepIndex,

                    showFormLoader: state.showFormLoader,
                };
            },
            prepare(isClinicHost: boolean) {
                return {
                    payload: {isClinicHost: isClinicHost}
                };
            }
        },
        changeIsInquiryFormUpdated: {
            reducer: (state: IFormState, action: IChangeIsInquiryFormUpdated) => {
                return {
                    initialized: state.initialized,
                    formSteps: state.formSteps,
                    inquiryForm: state.inquiryForm,
                    currentStep: state.currentStep,
                    inquiryId: state.inquiryId,
                    inquiryPaymentId: state.inquiryPaymentId,
                    inquiry: state.inquiry,
                    isInquiryCreated: state.isInquiryCreated,
                    isClinicHost: state.isClinicHost,
                    isInquiryFormUpdated: action.payload.isInquiryFormUpdated,
                    wizardHeadStepIndex: state.wizardHeadStepIndex,

                    showFormLoader: state.showFormLoader,
                };
            },
            prepare(isInquiryFormUpdated: boolean) {
                return {
                    payload: {isInquiryFormUpdated: isInquiryFormUpdated}
                };
            }
        },
        changeWizardHeadStepIndex: {
            reducer: (state: IFormState, action: IChangeWizardHeadStepIndex) => {
                return {
                    initialized: state.initialized,
                    formSteps: state.formSteps,
                    inquiryForm: state.inquiryForm,
                    currentStep: state.currentStep,
                    inquiryId: state.inquiryId,
                    inquiryPaymentId: state.inquiryPaymentId,
                    inquiry: state.inquiry,
                    isInquiryCreated: state.isInquiryCreated,
                    isClinicHost: state.isClinicHost,
                    isInquiryFormUpdated: state.isInquiryFormUpdated,
                    wizardHeadStepIndex: action.payload.wizardHeadStepIndex,

                    showFormLoader: state.showFormLoader,
                };
            },
            prepare(wizardHeadStepNo: number) {
                return {
                    payload: {wizardHeadStepIndex: wizardHeadStepNo}
                };
            }
        },
        changeFormInitialized : {
            reducer: (state: IFormState) => {
                return {
                    initialized: true,
                    formSteps: state.formSteps,
                    inquiryForm: state.inquiryForm,
                    currentStep: state.currentStep,
                    inquiryId: state.inquiryId,
                    inquiryPaymentId: state.inquiryPaymentId,
                    inquiry: state.inquiry,
                    isInquiryCreated: state.isInquiryCreated,
                    isClinicHost: state.isClinicHost,
                    isInquiryFormUpdated: state.isInquiryFormUpdated,
                    wizardHeadStepIndex: state.wizardHeadStepIndex,
                    showFormLoader: state.showFormLoader,
                };
            },
            prepare() {
                return {
                    payload: {}
                };
            }
        },
        changeShowFormLoader: {
            reducer: (state: IFormState, action: IChangeShowFormLoader) => {
                return {
                    initialized: state.initialized,
                    formSteps: state.formSteps,
                    inquiryForm: state.inquiryForm,
                    currentStep: state.currentStep,
                    inquiryId: state.inquiryId,
                    inquiryPaymentId: state.inquiryPaymentId,
                    inquiry: state.inquiry,
                    isInquiryCreated: state.isInquiryCreated,
                    isClinicHost: state.isClinicHost,
                    isInquiryFormUpdated: state.isInquiryFormUpdated,
                    wizardHeadStepIndex: state.wizardHeadStepIndex,

                    showFormLoader: action.payload.showFormLoader,

                };
            },
            prepare(showFormLoader: boolean) {
                return {
                    payload: { showFormLoader: showFormLoader }
                };
            }
        },
        resetFormAndInitialize: {
            reducer: () => {
                const state = Object.assign({}, initialState);
                state.initialized = true;

                return state;
            },
            prepare() {
                return {
                    payload: {}
                };
            }
        },
    }
});

export const {
    changeFormSteps,
    changeForm,
    changeCurrentStep,
    changeInquiryId,
    changeInquiryPaymentId,
    changeInquiry,
    changeIsClinicHost,
    changeIsInquiryFormUpdated,
    changeWizardHeadStepIndex,
    changeShowFormLoader,
    changeFormInitialized,
    resetFormAndInitialize,
} = formSlice.actions;

export default formSlice.reducer;
