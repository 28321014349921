import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import {StripeCardElement} from '@stripe/stripe-js';
import {InquiryFormStep} from '../../inquiryFormStep';
import './styles.scss';
import {calendarSelector, Form, FormControlChangeType, IFormConfig, Translation} from 'common-web';
import {paymentFormConfig} from '../paymentFormConfig';
import {connect} from 'react-redux';
import {RootState} from '../../../../store/reducers';
import {authTokenSelector} from '../../../../store/selectors/authSelectors';
import {
    formSelector,
    inquiryIdSelector,
    inquiryPaymentIdSelector,
    inquirySelector
} from '../../../../store/selectors/formSelectors';
import {changeForm, changeShowFormLoader, IInquiryForm} from '../../../../store/reducers/formSlice';
import {IStepManagerService} from '../../../../service/stepManagerService';
import {IAlertManagerService} from '../../../../service/alertManagerService';
import {fixInjectedProperties, lazyInject} from '../../../../ioc';
import {getPaymentSecret} from '../../../../api/getPaymentSecret';
import {confirmPayment} from '../../../../api/confirmPayment';
import {BehaviorSubject, Subscription, throwError, iif, of} from "rxjs";
import {catchError, switchMap, tap, filter, debounceTime} from "rxjs/operators";
import {getStripeAccountIdAPI} from "../../../../api/getStripeAccountId";

export interface ICheckoutFormProps {
    readonly inquiryForm: IInquiryForm;
    readonly inquiryId: string | null;
    readonly inquiryPaymentId: string | null;
    readonly inquiry: any;
    readonly authToken: string;
    readonly changeShowFormLoader: typeof changeShowFormLoader;
    readonly changeForm: typeof changeForm;
    readonly calendar: {[key: string]: any};
}

export interface ICheckoutFormState {
    readonly formConfig: typeof IFormConfig;
    readonly value: any;
    readonly isFormValid: boolean;
    readonly isStripeFormValid: boolean;
    readonly stripePresent: boolean;
    readonly vendorAccountId: string | null;
}

class CheckoutForm extends React.Component<ICheckoutFormProps, ICheckoutFormState> {
    @lazyInject('StepManagerService') private stepManager: IStepManagerService;
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    readonly stepName: InquiryFormStep = InquiryFormStep.PAYMENT;
    readonly onValueStateChange$: BehaviorSubject<any> = new BehaviorSubject(null);
    readonly subscriptions: Subscription[] = [];
    private isProcessing: boolean = false;
    private stripe: any;
    private elements: any;

    constructor(props: any) {
        super(props);

        fixInjectedProperties(this);

        this.state = {
            formConfig: null,
            value: null,
            isFormValid: false,
            isStripeFormValid: false,
            stripePresent: false,
            vendorAccountId: null
        }
    }

    componentDidMount() {
        if (this.props.calendar && this.props.calendar.account &&
        this.props.calendar.account.residency === 'TR') {
            this.setState({vendorAccountId: process.env.REACT_APP_STRIPE_ACCOUNT as string}, () => {
                this.updateFormControls()
            })
        } else {
            this.getStripeAccountId();
        }

        if (this.props.inquiryForm) {
            this.updateFormFromState();
        }

        this.subscriptions.push(
            this.onValueStateChange$.pipe(
                filter((data: any) => data && data.changeType === FormControlChangeType.User),
                tap(()  => this.isProcessing = true),
                debounceTime(500),
                tap((data: any)  => this.onFormValueChange(data.value)),
                tap(()  => this.isProcessing = false),
            ).subscribe()
        );
    }

    componentDidUpdate(
        prevProps: Readonly<ICheckoutFormProps>,
        prevState: Readonly<ICheckoutFormState>,
        snapshot?: any
    ): void {
        if (this.props.inquiryForm !== prevProps.inquiryForm) {
            this.updateFormFromState();
        }

        // if (this.state.vendorAccountId !== prevState.vendorAccountId) {
            // this.updateFormControls();
        // }
    }

    componentWillUnmount() {
        this.props.changeShowFormLoader(false);
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        const isFormDisabled = !this.state.isFormValid && !this.state.isStripeFormValid;
        return (
            <React.Fragment>
                <h2 className="header">
                    <Translation text={'form.consultationForm.payment.title'}/>
                </h2>
                <Form config={this.state.formConfig}
                      controlName={'paymentForm'}
                      onValueStateChange={this.onValueStateChange}
                      onValidationStateChange={this.onValidationStateChange}
                      value={this.state.value}/>

                <footer className="button-form-container">
                    <button onClick={() => this.stepManager.goTo(InquiryFormStep.SUMMARY)} // toDo fix account array issue
                            className="btn btn-prev">
                        <Translation text={`form.buttons.backToEdit`}/>
                    </button>
                    <button onClick={this.onFormSubmit}
                            className={`btn btn-next ${isFormDisabled ? 'disabled' : ''}`}>
                        <Translation text={`form.buttons.buy`}/>
                    </button>
                </footer>
            </React.Fragment>
        )
    }

    private onValueStateChange = (controlName: string, value: any, changeType: typeof FormControlChangeType) => {
        this.isProcessing = true;
        this.onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };

    private onValidationStateChange = (controlName: string, isValid: boolean) => {
        this.setState({isFormValid: isValid});
    };

    private goPrev = () => {
        if (this.isProcessing) {
            return;
        }
        this.isProcessing = true;
        this.stepManager.goPrev(this.stepName)
    };

    // ========== CUSTOM METHODS ========== //

    private updateFormFromState = () => {
        const inquiry = this.props.inquiryForm;
        this.setState({
            value: {
                cardholderName: inquiry.cardholderName,
                termsAndConditions: inquiry.termsAndConditions,
            }
        })
    };

    private onFormSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isProcessing || !this.props.inquiry || !this.props.inquiryPaymentId || !this.props.inquiryId) {
            return;
        }
        this.isProcessing = true;
        this.props.changeShowFormLoader(true);
        this.alertManager.paymentProcessing();

        let paymentId = this.props.inquiryPaymentId;
        const acceptInquiryFlowObservable = getPaymentSecret(paymentId, this.props.authToken).pipe(
            catchError((error: any) => {
                this.handleError(error);
                return of();
            }),

            switchMap((getPaymentSecretResp: any) => {
                const confirmCardPaymentPayload = {
                    payment_method: {
                        card: this.elements.getElement(CardElement) as StripeCardElement,
                        billing_details: {
                            name: this.state.value.cardholderName,
                        },
                    }
                };
                return this.stripe.confirmCardPayment(
                    getPaymentSecretResp.client_secret,
                    confirmCardPaymentPayload
                )
            }),

            switchMap((paymentResponse: any) => {
                if (paymentResponse.error) {
                    let errorMessage = paymentResponse.error && paymentResponse.error.message ? paymentResponse.error.message :
                        'Stripe: payment failed. Contact with our support.';
                    this.alertManager.addAlert(errorMessage);
                    this.props.changeShowFormLoader(false);
                    return of();
                } else {
                    return confirmPayment(paymentId, this.props.authToken, null).pipe(
                        catchError((error: any) => {
                            // should be handled by stripe events
                            // this.handleError(error);
                            this.alertManager.handlePaymentSuccess();
                            this.props.changeShowFormLoader(false);
                            this.stepManager.goNext(this.stepName);
                            return of();
                        }),
                        tap(() => {
                            this.alertManager.handlePaymentSuccess();
                            this.props.changeShowFormLoader(false);
                            this.stepManager.goNext(this.stepName);
                            return of();
                        })
                    )
                }
            })
        );

        this.subscriptions.push(
            acceptInquiryFlowObservable.subscribe()
        );
    };

    private handleError(error: any): void {
        this.alertManager.handleFormApiError(error.response);
        this.props.changeShowFormLoader(false);
    }

    private onFormValueChange = (value: any) => {
        const mappedValue: any = {};
        Object.keys(value).forEach((key: string) => {
            let fieldValue = value[key];
            if (undefined === fieldValue || null === fieldValue) {
                mappedValue[key] = null;

                return;
            }
            if (key === 'stripecard') {
                fieldValue = Object.assign({}, fieldValue);
                this.stripe = fieldValue['stripe'];
                this.elements = fieldValue['elements'];
                delete fieldValue['stripe'];
                delete fieldValue['elements'];
            } else {
                mappedValue[key] = fieldValue;
            }
        });
        this.props.changeForm(mappedValue);
    };

    private updateFormControls = () => {
        const updatedFormConfig = Object.assign({}, paymentFormConfig);
        updatedFormConfig.controls.map((control: any) => {
            if (control.hasOwnProperty("controls")) {
                Object.keys(control.controls).map((key: string) => {
                    if (key === 'stripecard') {
                        control.controls[key].stripeAccount = this.state.vendorAccountId;
                    }
                });
            }

            return control;
        });

        return this.setState({formConfig: updatedFormConfig});
    };

    private getStripeAccountId = () => {
        let clinicId = this.props.calendar.account.id;

        if (this.props.inquiryPaymentId && clinicId) {
            getStripeAccountIdAPI(this.props.inquiryPaymentId, clinicId, this.props.authToken).pipe(
                tap((resp: any) => {
                    this.setState({vendorAccountId: resp.vendor_account_id}, () => this.updateFormControls())
                }),
                catchError((error: any) => {
                    return of(this.alertManager.handleFormApiError(error))
                })
            ).subscribe()
        }
    }

}

export default connect(
    (state: RootState) => ({
        authToken: authTokenSelector(state),
        inquiryForm: formSelector(state),
        inquiryId: inquiryIdSelector(state),
        inquiryPaymentId: inquiryPaymentIdSelector(state),
        inquiry: inquirySelector(state),
        calendar: calendarSelector(state)
    }),
    {
        changeShowFormLoader,
        changeForm
    }
)(CheckoutForm);
