import {Observable} from "rxjs";
import {clinicHunterRegistrationAPI} from "./provider/clinicHunterRegistration";
import {RestQueryParams} from "common-web";

export function registerPatientAPI(
    login: string,
    plainPassword: string,
): Observable<any> {
    return clinicHunterRegistrationAPI.post(
        "register-patient",
        {
            login: login,
            plainPassword: plainPassword,
        },
        new RestQueryParams()
    );
}
