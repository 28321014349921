import {injectable} from 'inversify';
import {changeForm, IInquiryForm} from '../store/reducers/formSlice';
import store from "../store";

export interface IInquiryFormUpdateService {
    updateInquiryFormFromAPI(apiData: any): any;
}

@injectable()
export class InquiryFormUpdateService implements IInquiryFormUpdateService {

    public updateInquiryFormFromAPI(apiData: any): any {
        const updatedGlobalStateInquiry: IInquiryForm = {
            onlineConsultationId: apiData.id,
            calendarId: apiData.calendar.id,
            consultationDate: apiData?.startsAt,
            cid: apiData.clinic?.id,
            name: apiData.createdBy ? apiData.createdBy.firstName : null,
            lastName: apiData.createdBy ? apiData.createdBy.lastName : null,
            additionalComments: apiData.notes,
            cardholderName: null,
            loginEmail: null,
            loginPassword: null,
            registrationEmail: null,
            registrationPassword: null,
            registrationPasswordConfirm: null,
            registrationTermsAgreement: null,
            termsAndConditions: null,
            quote: apiData?.quote
        };

        store.dispatch(changeForm(updatedGlobalStateInquiry));
    };
}

export default InquiryFormUpdateService;

